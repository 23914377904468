<div class="background-image-container mt-ncs">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
          <h6 class="display-3">Welcome to EMST Official Repository</h6>
          <p class="lead"></p>
	  <br/> <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>

        </div>
      </div>
    </div>
  </div>
  <picture class="background-image">
    <source type="image/jpg" srcset="https://www.emst.gr/web3/wp-content/uploads/2024/03/CLAUDIA-COMTE_slider.jpg 2000w, https://www.emst.gr/web3/wp-content/uploads/2024/03/CLAUDIA-COMTE_slider.jpg 1200w, https://www.emst.gr/web3/wp-content/uploads/2024/03/CLAUDIA-COMTE_slider.jpg 768w">
    <img alt="" [src]="'https://www.emst.gr/web3/wp-content/uploads/2024/03/CLAUDIA-COMTE_slider.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
</div>
